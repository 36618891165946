@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
  /* background-color: var(--colorGrey50); */
  position: relative;
}

.filters {
  & > *:not(:last-child) {
    margin-right: 8px;
    margin-bottom: 8px;
    height: auto;
  }
  & > :nth-child(3){
    & > div{
      & > div{
        background-color:#fc334d;
        z-index: 1;
      }
    }
  }
}
